
.birthday {
  height: 120px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  overflow: hidden;
  background-color: #fff;
}

.birthday div:first-child {
  flex-basis: 35%;
}

.birthday div:last-child {
  flex-basis: 65%;
  padding: 0 0.5rem 0.25rem;
}

.birthday img {
  display: block;
  width: 68%;
  height: 140px;
  object-fit: inherit;
}

.birthday p {
  margin-top: 0.5rem;
  margin-bottom: 0.35rem;
}

.newbirth {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.border {
  border: 1px solid #ddd;
}

.curve {
  border-radius: 0.35rem;
}

@media (min-width: 768px) {

  .birthday div:first-child {
    flex-basis: 30%;
  }

  .birthday div:last-child {
    flex-basis: 70%;
  }
}

