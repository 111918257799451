@keyframes count {
    from {
      content: attr(data-start);
    }
    to {
      content: attr(data-end);
    }
  }
  
  .dataCount {
    animation: count 2s ease-out forwards;
  }
  