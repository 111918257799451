.home-page-welcome {
    position: relative;
    padding: 18px 0;
    background-size: cover;
    background-image: url(https://cms.loreto.in/slideimages/slide2.jpg);
    z-index: 1;
}

.welcome-content .entry-title {
    position: relative;
    padding-bottom: 24px;
    font-size: 22px;
    color: #012c6d;
    text-align: center;
    font-family: 'Cabin' !important;
    padding: 0px;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0 auto;
}

.welcome-content .entry-content {
    font-size: 16px;
    line-height: 2;
    color: #262626;
    font-family: 'Cabin' !important;
    font-weight: 400;
    text-align: center;
    border-radius: 10px;
}

.home-page-welcome::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgba(250, 250, 250, 0.8);
}

.btn-dark {
    border-radius: 50px;
}