.carousel {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
}

@media (max-width: 768px) {
    .carousel {
        height: 14vh !important;
    }
}

@media (max-width: 576px) {
    .carousel {
        height: 30vh;
    }
}

.carousel .carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel .carousel-inner .carousel-item {
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel .carousel-inner .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 768px) {
    .carousel .carousel-inner .carousel-item {
        height: 70vh;
    }
}

@media (max-width: 480px) {
    .carousel .carousel-inner .carousel-item {
        height: 30vh;
    }
}

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
}

.carousel .carousel-control-prev {
    left: 0;
}

.carousel .carousel-control-next {
    right: 0;
}

.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
}

.carousel .carousel-control-prev-icon::before {
    content: '\e002';
}

.carousel .carousel-control-next-icon::before {
    content: '\e003';
}