#scroll_this {
  width: 100%;
  height: 460px;
  padding-right: 2px;
  overflow: hidden;
}
#scroll_this.scroll-visible {
  overflow: auto;
}
.birthday-box {
  width: 90%;
  height: 100px;
  box-shadow: 10px 10px 10px rgba(136, 136, 136, 0.25), 5px 5px 10px rgba(136, 136, 136, 0.11) inset;
  overflow: hidden;
  margin-bottom: 17px;
}
#imgstyles {
  height: 100px;
  width: 100px;
  float: left;
  /* object-fit: cover; */
  box-shadow: 5px 5px 5px rgba(136, 136, 136, 0.25), 5px 5px 10px rgba(136, 136, 136, 0.11) inset;
}
.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.emp_details {
  margin-left: 8rem;
  line-height: 14px;
  margin-top: 12px;
}
/* Webkit Scrollbar CSS */
#scroll_this::-webkit-scrollbar {
  width: 5px;
}

#scroll_this::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

#scroll_this::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

#scroll_this::-webkit-scrollbar-thumb:hover {
  background: #f2f2f2;
}
